import React from "react";
import PropTypes from "prop-types";

import styles from "./StripCustomContent.module.scss";

const propTypes = {
	customContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired
};

// ${styles.whatIDoBlock
function StripCustomContent({ customContent }) {
	const topPad = "p-t-xl";
	const botPad = "m-b-xl";

	return (
		<section
			className={`hero ${styles.backgroundHelper} ${styles.backgroundGrey} ${topPad}`}
		>
			<div className={`container maxContainer outerContainer ${botPad}`}>
				{customContent}
			</div>
		</section>
	);
}

StripCustomContent.propTypes = propTypes;
// StripCustomContent.defaultProps = defaultProps;

export default StripCustomContent;
