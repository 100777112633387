import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";

import { CONTACT_ME_PAGE } from "src/routesConstants";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import StripsAlternatingContent from "src/components/generic/StripsAlternatingContent/StripsAlternatingContent";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";

// Import videos
import speedVid from "static/images/pages/dashboard_comparison_sped_up.mp4";
import StripCustomContent from "src/components/generic/StripCustomContent/StripCustomContent";
import HowItWorks from "src/components/generic/HowItWorks/HowItWorks";

// Styles
// import styles from "./index.module.scss";

const stripPrimary = {
	title: "Whole SERP tracking with custom reports.",
	subTitle: (
		<div>
			<p>
				If you&apos;re looking for rich rank tracking data with deep
				reporting we can help.
			</p>
			<p>
				We work with Data For SEO to ingest full SERP data into your
				warehouse and then provide rich reports off the back of it.
			</p>
		</div>
	)
};
const rankTrackingContent1 = [
	{
		subTitle: (
			<span className="m-r-md">What kind of reports do we have?</span>
		),
		content: (
			<span className="m-r-md">
				<p>
					We build reports to try and help take advantage of the
					richness of the data.
				</p>
				<p>
					And we build reports specifically for the task they need to
					accomplish.
				</p>
				<p>
					We&apos;ve got a set of primary public facing reports you
					can have a look around at on the right.
				</p>
			</span>
		),
		secondContent: (
			<React.Fragment>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/a2c4a21e-26a3-4cea-9348-d9d9d94c6535/"
							target="blank"
							rel="noopener noreferrer"
						>
							Rank Tracking
						</a>
					</h3>
					<span>Analyse rich rank tracking data.</span>
				</div>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/6848ee68-c73c-40a5-99bb-90da18fc26d5/"
							target="blank"
							rel="noopener noreferrer"
						>
							Branded SERP Monitoring
						</a>
					</h3>
					<span>Monitor and control your brand image on Google.</span>
				</div>

				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/reporting/9abad88d-89cf-44ca-8762-d20ec072ac00/"
							target="blank"
							rel="noopener noreferrer"
						>
							Featured Snippet Tracking
						</a>
					</h3>
					<span>
						A template for finding featured snippets which can be
						won and targeting them.
					</span>
				</div>
			</React.Fragment>
		)
	},
	{
		subTitle: "Where do we sit in the rank tracking landscape?",
		content: (
			<React.Fragment>
				<ul>
					<li>
						Rich data: We load a very rich SERP schema that is
						constantly being updated from DataForSEO
					</li>
					<li>
						Affordable: We&apos;re somewhere in the middle of cost.
					</li>
					<li>
						Customisation: We have a lot of configurable options.
						This means we&apos;re not a straight forward plug and
						play solution for basic data.
					</li>
				</ul>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle:
			"We store as much data as possible, because you can't rewind to get more.",
		content: (
			<React.Fragment>
				<p>
					Once today has passed, you can&apos;t go back and get that
					SERP data again so what you store matters.
				</p>
				<p>We surface the parts you need the most in the reports.</p>
				<p>
					But we have the full SERP data stored across 300+ different
					fields to let you examine every part of it.
				</p>
				<p>
					If competitors change, you need to focus on a different set
					of SERP features, or dive into knowledge box links:
					we&apos;ve got it ready to go.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Raw data is all yours",
		content: (
			<React.Fragment>
				<p>
					Want to dig into the raw data? No problem, you&apos;ll have
					all of it.
				</p>
				<p>
					Get your BI team to look at it, or dive in yourself if you
					want to dig into the dirty details and roll up your hands
					with SQL and CSVs.
				</p>
				<p>
					Because it&apos;s stored in your warehouse, it&apos;s yours
					forever whether or not you stay with us. The history of your
					site is yours.
				</p>
			</React.Fragment>
		),
		image: null
	}
];

const reportingAlternating = [
	{
		subTitle: "Explore some of our reporting templates.",
		content: (
			<React.Fragment>
				<p>
					We&apos;ve anonymised the data for some reports so we can
					share them publically.
				</p>
			</React.Fragment>
		)
	},
	{
		subTitle: "Fast and insightful dashboards",
		content: (
			<React.Fragment>
				<p>
					As data scales, dashboards slow down. We pre-build our data
					pipelines to allow your reports to run faster while still
					being insightful.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Connecting with popular reporting platforms",
		content: (
			<React.Fragment>
				<p>
					We (primarily) use BigQuery for building the data
					warehouses.
				</p>
				<p>
					It connects with all of the popular reporting platforms,
					Data Studio, PowerBI, Tableau etc.
				</p>
				<p>
					We build our templates in Looker (Data) Studio, but all the
					business logic is in the underlying tables, so you can
					recreate them in any BI tool of choice.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "We've already got pre-built queries you can use",
		content: (
			<React.Fragment>
				<p>
					Want to see year on year traffic for a portfolio of sites
					split by brand and non-brand? We&apos;ve got a query for
					that.
				</p>
				<p>
					Want to report on traffic and crawling across a migration?
					We&apos;ve got a query for that.
				</p>
				<p>
					Want to see traffic over time for a specific list of pages,
					or each template on your websites? We&apos;ve got a query
					for that.
				</p>
				<p>If we don&apos;t have it, we&apos;ll make it for you.</p>
			</React.Fragment>
		),
		image: null
	}
];

const propTypes = {
	data: PropTypes.shape().isRequired,
	location: PropTypes.shape().isRequired
};

const Index = ({ data, location }) => {
	// Content for main

	// Set images
	rankTrackingContent1[1].image =
		data.rankTrackingContent_img2.childImageSharp.fluid;
	rankTrackingContent1[2].image =
		data.rankTrackingContent_img0.childImageSharp.fluid;
	rankTrackingContent1[3].image =
		data.rankTrackingContent_img1.childImageSharp.fluid;

	//  Lots of websites

	// Reporting
	reportingAlternating[0].image = data.reporting0.childImageSharp.fluid;
	reportingAlternating[1].video = speedVid;
	reportingAlternating[2].image = data.reporting1.childImageSharp.fluid;
	reportingAlternating[3].image = data.reporting2.childImageSharp.fluid;

	const title = "Rank Tracking Service";
	const description =
		"Now the data is loaded, we&apos;ve got huge amounts of data at our fingertips. We need working with it to be quick and painless, so we can get to problem solving and doing our jobs.";

	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO
				pageTitle={title}
				pageDescription={description}
				location={location}
			/>
			<StripPrimary
				title={stripPrimary.title}
				subTitle={stripPrimary.subTitle}
				fullSized={true}
				isWhite={true}
				demoButton={true}
				reportingButton={false}
			/>
			<section>
				{/* <StripTitle title="How do we load data?" /> */}
				<StripsAlternatingContent
					stripList={rankTrackingContent1}
					classes="m-t-xxl"
					noBotMargin={true}
					largeTitle={true}
				/>
			</section>
			<StripCustomContent
				customContent={
					<div className="columns is-desktop">
						<div className="column">
							<h2>Pricing</h2>
							<p>
								We don&apos;t have a full calculator on site at
								the moment, however here are some guide prices.
							</p>
							<p>
								Keyword don&apos;t actually need to be tracked
								daily, we also support weekly and monthly and
								those are priced accordingly (i.e. at 1/7 and
								~1/30.5).
							</p>
							<a
								className={`button is-link is-primary`}
								href={CONTACT_ME_PAGE}
							>
								Book a demo
							</a>
						</div>
						<div className="column">
							<div className="pricingBox noFix">
								<h3>Guide pricing</h3>
								<table>
									<thead>
										<tr>
											<th>Daily keywords tracked</th>
											<th>Price</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1,000</td>
											<td>£241</td>
										</tr>
										<tr>
											<td>3,000</td>
											<td>£322</td>
										</tr>
										<tr>
											<td>5,000</td>
											<td>£404</td>
										</tr>
										<tr>
											<td>10,000</td>
											<td>£580</td>
										</tr>
										<tr>
											<td>20,000</td>
											<td>£922</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				}
			/>
			<HowItWorks
				title="How does the rank data service work?"
				contentSetKey="rankService"
			/>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query rankTrackingService {
		# Large websites
		dataAllInOne: file(
			relativePath: { eq: "images/pages/data-diagram.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 933, maxHeight: 658) {
					...GatsbyImageSharpFluid
				}
			}
		}
		rankTrackingContent_img0: file(
			relativePath: { eq: "images/pages/rank-service/big-schema.png" }
		) {
			childImageSharp {
				fluid(maxWidth: 477, maxHeight: 655) {
					...GatsbyImageSharpFluid
				}
			}
		}
		rankTrackingContent_img1: file(
			relativePath: { eq: "images/pages/rank-service/rank_sql.png" }
		) {
			childImageSharp {
				fluid(maxWidth: 683, maxHeight: 698) {
					...GatsbyImageSharpFluid
				}
			}
		}
		rankTrackingContent_img2: file(
			relativePath: {
				eq: "images/pages/rank-service/where-do-we-fall.png"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 738, maxHeight: 437) {
					...GatsbyImageSharpFluid
				}
			}
		}

		# Lots of websites
		differentProperties: file(
			relativePath: {
				eq: "images/pages/use_cases/different_properties.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 501, maxHeight: 393) {
					...GatsbyImageSharpFluid
				}
			}
		}
		lotsOfWebsites2: file(
			relativePath: { eq: "images/pages/use_cases/single_aggregated.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 715, maxHeight: 301) {
					...GatsbyImageSharpFluid
				}
			}
		}

		# Reporting
		reporting0: file(
			relativePath: { eq: "images/pages/use_cases/filter_logic.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 753, maxHeight: 592) {
					...GatsbyImageSharpFluid
				}
			}
		}
		reporting1: file(
			relativePath: {
				eq: "images/pages/use_cases/reporting_integrations.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 450, maxHeight: 338) {
					...GatsbyImageSharpFluid
				}
			}
		}
		reporting2: file(
			relativePath: { eq: "images/pages/use_cases/example_queries.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 560, maxHeight: 377) {
					...GatsbyImageSharpFluid
				}
			}
		}
		reporting3: file(
			relativePath: { eq: "images/pages/use_cases/data_studio_error.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 752, maxHeight: 582) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
